<app-header></app-header>
<section class="globalContainer">
  <section class="verifyProductContainer pt70 px15 pb40">
    <h4 class="mt-4 backToProfile">
      <i class="mdi mdi-keyboard-backspace backIcon" (click)="onClickBack()"></i
      >Kembali ke Profil
    </h4>
    <div class="text-center mt-5">
      <img
        class=""
        *ngIf="!iSprintData.is_isprint"
        src="../../../assets/images/congratsproduct.png"
      />
      <img
        class=""
        *ngIf="iSprintData.is_isprint"
        src="../../../assets/images/ChocoBIB450g 1.png"
      />
    </div>
    <p class="text-center verifyContent">
      Produk Anda asli <br />
      dari Abbott PediaSure
      <img src="../../../assets/images/tick.png" class="imgTick" alt="tick" />
    </p>

    <p class="redeemContent text-center">
      {{ "VERIFY_PRODUCT.REDEEM_AND_COLLECT" | translate }}
    </p>

    <button
      class="btn btnPurple btnSandal br10 btn-block btn-56 ambilBtn"
      [disabled]="disabled || disable"
      (click)="onclickCheckPoint()"
    >
      <img class="mr-2" src="../../../assets/images/pointswhiterounded.png" />{{
        "VERIFY_PRODUCT.REDEEM" | translate
      }}
    </button>

    <button
      class="btn btnPurple btnPurpleOutline br10 btn-block mt20 noBorder"
      routerLink="/profile/my-points"
      *ngIf="disabled"
    >
      <img class="mr-2" src="../../../assets/images/clock.png" />Cek Status Poin
    </button>

    <a
      class="text-center checkProduct ambilBtn"
      [routerLink]="['/traceability', { data: 2 }]"
      ><i class="mdi mdi-alert-circle-outline"></i>
      Cek detail produk
    </a>
  </section>
</section>
<app-footer></app-footer>

<div
  bsModal [config]="{backdrop: 'static'}"
  #congratsModalStepOne="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <img class="max-240" src="../../../assets/images/wohoo.gif" />
        </div>
        <div class="text-center">
          <h5 class="mt-3">Simpan struk Anda</h5>

          <p>
            Untuk kenyamanan Anda nantinya, kami rekomendasikan untuk menyimpan
            foto struk Anda.
          </p>
        </div>
        <button
          [disabled]="disabledOke"
          class="btn btnPurple br10 btn-block mt40 btn-50"
          (click)="onClickCancelModalone()"
        >
          Oke
        </button>
      </div>
    </div>
  </div>
</div>
<div
  bsModal [config]="{backdrop: 'static'}"
  #congratsModalStepTwo="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <img class="" src="../../../assets/images/rewardpoints.png" />
        </div>
        <div class="text-center">
          <h5 class="mb20">Selamat!!🎉</h5>

          <!-- <p>
            Permintaan Anda sedang diproses, Anda akan mendapatkan poin dalam
            waktu 7x24 jam proses.
          </p> -->
          <p>
            Point Anda sedang dalam proses validasi. Cek berkala akun loyalty
            Anda hingga hari ke tujuh
          </p>
        </div>
        <button
          class="btn btnPurple br10 btn-block mt40 btn-50"
          (click)="onClickCancelModalTwo()"
        >
          Oke
        </button>
        <p class="font-weight-bold textGrey text-center mt20">
          Cek Syarat dan Ketentuan
        </p>
      </div>
    </div>
  </div>
</div>

<div
  bsModal [config]="{backdrop: 'static'}"
  class="modal fade customModalContainer px30 exceedLimitModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  #exceedLimitModal="bs-modal"
>
  <div class="modal-dialog modal-sm mx-auto">
    <div class="modal-content">
      <span class="closeIcon"><i class="mdi mdi-close"></i></span>
      <div class="modal-body pt-3 text-center">
        <img src="../../../assets/images/prod-warn.png" alt="warning" />
        <h2 class="pointsModalTilte fW600 text-center mt-2">Maaf...</h2>
        <p>
          Pembelian Anda sudah melebihi batasan kuota konsumsi si Kecil. Untuk
          keterangan lebih lanjut Anda bisa menghubungi tim Pediasure
        </p>
        <div class="row mt20">
          <div class="col text-left pr-2">
            <button
              class="btn btn btnPurple btnOutlinePurple fW600 btn-block br10 btn-50"
            >
              <a href="https://pediasure.co.id/contact" style="color: #5c0b8a"
                >Hubungi Tim</a
              >
            </button>
            <!-- <a href="https://pediasure.co.id/contact">{{ 'FOOTER.CONTACTUS' | translate }} </a> -->
          </div>
          <div class="col text-right pl-2">
            <button
              class="btn btn btnPurple btn-block fW600 btn-50 br10"
              (click)="onClickOk()"
            >
              Oke
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div
  bsModal [config]="{backdrop: 'static'}"
  #processingModal="bs-modal"
  class="modal fade customModalContainer"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <div class="text-center">
          <div class="text-center">
            <img
              class="max-240"
              src="../../../assets/images/loadingImageBucket.gif"
            />
          </div>
          <p>{{ "VERIFY_PRODUCT.PROCESSING" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
