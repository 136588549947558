import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { ToastrModule } from 'ngx-toastr';
import { TraceabilityModule } from './traceability/traceability.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { SharedModule } from './shared/shared.module';
import { LoginSignupModule } from './login-signup/login-signup.module';
import { ApiService } from './service/api.service';
import { ProfileModule } from './profile/profile.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FaqPrivacyTncModule } from './faq-privacy-tnc/faq-privacy-tnc.module';
import { SpinWheelModule } from './spin-wheel/spin-wheel.module';

import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { SafePipeModule } from 'safe-pipe';
import { NgxPaginationModule } from 'ngx-pagination';

import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { environment as env } from '../environments/environment';

export function errorHandlerFactory() {
  return new BugsnagErrorHandler();
}
Bugsnag.start({
  apiKey: '5d560bdec2d29474c46704c946224a5f',
  appVersion: env.bugsnag_version,
  collectUserIp: false
});


// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,

    // HeaderComponent,
    // FooterComponent
  ],
  imports: [
    ModalModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    SocialLoginModule,
    HomeModule,
    NgxSpinnerModule,
    SharedModule,
    FaqPrivacyTncModule,
    TraceabilityModule,
    LoginSignupModule,
    SpinWheelModule,
    ProfileModule,
    SafePipeModule,
    NgxPaginationModule,
    
    ToastrModule.forRoot({ timeOut: 2000 }),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    ApiService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            // provider: new GoogleLoginProvider(
            //   '537495054912-moh7v9fq15t7b0jpgpggqietbj85e3i6.apps.googleusercontent.com'
            // )
            provider: new GoogleLoginProvider(
              '1048937394603-4g5bijm5d1jcskn3gt7g9fpbn44mf02n.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
          //provider: new FacebookLoginProvider('405854974436260'),
           // provider: new FacebookLoginProvider('467323527303055'),
           provider: new FacebookLoginProvider('584501336635710'),

            // App ID: 584501336635710
            // App Secret: ff028b34f653376412f32a63f02213f7
            
          },
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
