import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Location } from '@angular/common';
import { first, windowWhen } from 'rxjs/operators';
import { ApiService } from 'src/app/service/api.service';
import { environment as env } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import { BugsnagService } from 'src/app/service/bugsnag.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-verify-product',
  templateUrl: './verify-product.component.html',
  styleUrls: ['./verify-product.component.scss'],
})
export class VerifyProductComponent implements OnInit {
  iSprintData: any;
  disabled: boolean = false;
  prodCategory: any;
  customerData: any;
  customerName: any;
  latitude: any;
  longitude: any;
  scanCode: any;
  prodImage: any;
  disable: any;
  @ViewChild('congratsModalStepOne', { static: true })
  congratsModalStepOne: ModalDirective;
  @ViewChild('processingModal', { static: true })
  processingModal: ModalDirective;
  @ViewChild('congratsModalStepTwo', { static: true })
  congratsModalStepTwo: ModalDirective;
  @ViewChild('exceedLimitModal', { static: true })
  exceedLimitModal: ModalDirective;

  suspiciousCount = 0;
  image: string;
  confidence: string;
  kidsLength: any;
  weight: any;
  isWeightEmpty: boolean;
  disabledOke: boolean = false;

  constructor(
    private location: Location,
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router, 
    private bsService: BugsnagService,
    private toastr: ToastrService,
  ) {
    window.scrollTo({ top: 0 });
    this.scanCode = this.route.snapshot.params.scanCode;
    console.log(this.scanCode, 'this.scanCode ======== ');
    let scancodeData = JSON.parse(sessionStorage.getItem('iSprintData'));
    console.log(scancodeData, 'scancodeData========');
    this.prodCategory = scancodeData?.brand;
    // this.scanCode = sessionStorage.getItem('scanCode');
    // console.log(this.scanCode, 'scannedCode=========');
    // this.scanCode = sessionStorage.getItem('scanCode');
    // console.log(this.scanCode, 'scannedCode');
    this.prodCategory = scancodeData?.product?.categoryCode;
    this.kidsLength = JSON.parse(sessionStorage.getItem('kidsLength'));
    this.kidsLength =
      this.kidsLength == 0 ||
      this.kidsLength == null ||
      this.kidsLength == undefined
        ? '1'
        : JSON.stringify(this.kidsLength);
    console.log('kids: ', this.kidsLength);
  }

  ngOnInit(): void {
    this.spinner.hide();
    //this.congratsModalStepTwo.show();
    this.iSprintData = sessionStorage.getItem('iSprintData');
    this.iSprintData = JSON.parse(this.iSprintData);
    console.log(this.iSprintData);
    this.getCustomer();
    this.getPosition();
    this.weight = this.iSprintData.size;
    //this.weight = '05X0200GRS';
    this.isWeightEmpty = this.isEmpty(this.weight);
    if (this.isWeightEmpty) {
      this.weight = this.weight.includes('GRS')
        ? this.weight.replace('GRS', '')
        : this.weight.replace('GR', '');
      if (this.weight.includes('X')) {
        let weightArray = this.weight.split('X');
        let noOfItems = weightArray[0];
        let quantity = weightArray[1];
        console.log(noOfItems, 'noOfItems', quantity, 'quantity');
        this.weight = (parseInt(quantity) * noOfItems) / 1000;
      } else {
        this.weight = parseInt(this.weight) / 1000;
      }
    } else {
      this.weight = 0;
    }
    console.log('weight: ', this.weight);
    this.weight = JSON.stringify(this.weight);
    sessionStorage.setItem('productWeight', this.weight);
  }

  isEmpty(weight) {
    if (weight == '' || weight == undefined || weight == null) {
      return false;
    } else {
      return true;
    }
  }

  getPosition() {
    this.apiService.getPosition().then((pos) => {
      let lat = pos.lat;
      let lang = pos.lng;
      let confidence = pos.accuracy;
      this.latitude = JSON.stringify(lat);
      this.longitude = JSON.stringify(lang);
      this.confidence = JSON.stringify(confidence);
    });
  }
  onClickBack() {
    this.location.back();
  }
  onclickCheckPoint() {
    this.spinner.hide();
    this.disabled = true;
    this.congratsModalStepOne.show();
  }

  closeCancelMembershipModal() {
    return;
  }

  getCustomer() {
    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    let getcustresp;
    this.apiService.getCapCustomer(mobile, token).subscribe((res:any) => {
      getcustresp = res;
      console.log(res,'getcust resp')
      if(res?.status?.code == 200){
      // Block the user
      let block = res['customers']['customer'][0].custom_fields.field;
      let blockUser = block.filter((filt) => {
        return filt.name == 'block_count';
      });
      console.log(blockUser, 'blockUser===');
      if (blockUser[0]?.value == '1') {
        sessionStorage.clear();
        this.router.navigate(['/blocker']);
      }
      this.spinner.hide();
      this.customerData = res['customers']['customer'][0];
      console.log(this.customerData);
      this.customerName =
        this.customerData.firstname + ' ' + this.customerData.lastname;
      console.log(this.customerName);
      this.customerData.custom_fields.field.forEach((element) => {
        if (element.name === 'suspicious_count') {
          this.suspiciousCount = parseInt(element.value);
        }
      });
    }else if(res?.status.code == 401){
      this.apiService.showToaster('Token Anda kedaluwarsa, Silakan masuk lagi');
      this.bsService.notifyError(res);
      this.spinner.hide();
      sessionStorage.clear();
      this.router.navigate(['/login-signup'])
    }else{
      this.apiService.showToaster('Ada yang salah!, Silakan coba lagi');
      this.spinner.hide();
      this.bsService.notifyError(res);
    }
    },err=>{
      this.spinner.hide();
      console.log(err, '=== get customer error =======');
      this.bsService.notifyError(err, token);
    });
    console.log(this.suspiciousCount, 'suspiciousCount');
  }
  parseFunction(value) {
    return JSON.parse(value);
  }

  onClickCancelModalone() {
    // this.getCustomer();

    let mobile = sessionStorage.getItem('mobileNo');
    let token = sessionStorage.getItem('authToken');
    let key = sessionStorage.getItem('authKey');
    this.spinner.show();
    this.apiService.getCapCustomer(mobile, token).subscribe((res) => {
      // Block the user
      let block = res['customers']['customer'][0].custom_fields.field;
      let blockUser = block.filter((filt) => {
        return filt.name == 'block_count';
      });
      console.log(blockUser, 'blockUser===');
      if (blockUser[0]?.value == '1') {
        sessionStorage.clear();
        this.router.navigate(['/blocker']);
      }
      this.spinner.hide();
      this.customerData = res['customers']['customer'][0];
      console.log(this.customerData);
      this.customerName =
        this.customerData.firstname + ' ' + this.customerData.lastname;
      console.log(this.customerName);
      this.customerData.custom_fields.field.forEach((element) => {
        if (element.name === 'suspicious_count') {
          this.suspiciousCount = parseInt(element.value);
        }
      });

      this.congratsModalStepOne.hide();
      this.processingModal.show();
      this.disabledOke = true;

      // let mobile = sessionStorage.getItem('mobileNo');
      let scanCode = sessionStorage.getItem('scanCode');

      // let token = sessionStorage.getItem('authToken');
      if (this.iSprintData.is_isprint) {
        console.log('image: ', this.image);
        let parsedPrimaryValue = this.parseFunction(
          this.iSprintData.product_name
        ).en_US;
        let prdname = parsedPrimaryValue;
        let formData = {
          scan_code: this.scanCode,
          mobile: mobile,
          cap_device_id: env.DEVICE_ID,
          customer_name: this.customerName,
          suspicious_count: this.suspiciousCount,
          product_name: prdname,
          product_image: this.iSprintData?.product_image,
          confidence: this.confidence,
          child_count: this.kidsLength,
          weight:
            this.weight == undefined || this.weight == null ? '0' : this.weight,
          //"weight": this.weight,
          custom_fields: [
            {
              name: 'translat',
              value: this.latitude,
            },
            {
              name: 'translong',
              value: this.longitude,
            },
            {
              name: 'productname',
              value: prdname ? prdname : 'Pediasure',
            },
            {
              name: 'categorycode',
              value: prdname ? prdname : 'Pediasure',
            },
            {
              name: 'sfproductcode',
              value: this.iSprintData.sku ? this.iSprintData.sku : 'Null',
            },
          ],
        };
        this.spinner.show();
        console.log(formData, 'form data');
        let isprintdata;
        this.apiService
          .postGpassEarnPoints(formData, token)
          .pipe(first())
          .subscribe(
            (res: any) => {
              isprintdata = res;
              this.spinner.hide();
              this.processingModal.show();
              if (res['status'] == 'success') {
                this.processingModal.hide();
                this.congratsModalStepTwo.show();
                console.log(res);
              }
            },
            (err) => {
              this.spinner.hide();
              console.log(err);
              //alert(err.error['message'])
              this.processingModal.hide();

              if (err.status == 420) {
                this.exceedLimitModal.show();
              } else {
                alert(err.error.message);
                this.router.navigate(['/profile']);
              }
              this.disable = true;
              this.spinner.hide();
              console.log(err, '=== get customer error =======');
              this.bsService.notifyError(err, formData);
            }
          );
      } else {
        this.spinner.show();
        let prdimg;
        this.apiService.getGpasProductImage(this.iSprintData.sku).subscribe(
          (res: any) => {
            prdimg = res;
            this.spinner.hide();
            console.log(res.data, 'getGpasProductImage');
            let brand = this.iSprintData.brand;
            let size = this.iSprintData.size;
            let prdname = brand + ' ' + size;
            this.prodImage = res.data[0]?.image;
            let formData = {
              scan_code: this.scanCode,
              mobile: mobile,
              customer_name: this.customerName,
              suspicious_count: this.suspiciousCount,
              product_name:
                this.iSprintData.brand +
                ' ' +
                this.iSprintData.flavor +
                ' ' +
                this.iSprintData.size,
              product_image: this.prodImage,
              latitude: this.latitude,
              longitude: this.longitude,
              confidence: this.confidence,
              child_count: this.kidsLength,
              weight:
                this.weight == undefined || this.weight == null
                  ? '0'
                  : this.weight,
              //"weight": this.weight,
              custom_fields: [
                {
                  name: 'productname',
                  value:
                    this.iSprintData.brand +
                    ' ' +
                    this.iSprintData.flavor +
                    ' ' +
                    this.iSprintData.size,
                },
                {
                  name: 'sfproductcode',
                  value: this.iSprintData.sku ? this.iSprintData.sku : 'Null',
                },
              ],
            };
            this.spinner.show();
            let gapsdata;
            this.apiService
              .postGpassEarnPoints(formData, token)
              .pipe(first())
              .subscribe(
                (res: any) => {
                  gapsdata = res;
                  this.spinner.hide();
                  console.log('res: ', res);
                  this.processingModal.show();

                  if (res['status'] == 'success') {
                    this.processingModal.hide();
                    this.congratsModalStepTwo.show();
                    this.disabled = true;
                    this.disable = true;
                    console.log(res);
                  }
                },
                (err) => {
                  this.spinner.hide();
                  //alert(err.error.message);
                  this.processingModal.hide();

                  if (err.status == 420) {
                    this.exceedLimitModal.show();
                  } else {
                    alert(err.error.message);
                    this.router.navigate(['/profile']);
                  }
                  this.spinner.hide();
                  console.log(err, '=== get customer error =======');
                  this.bsService.notifyError(err, formData);
                }
              );
          },
          (err) => {
            this.spinner.hide();
            console.log(err);
            this.spinner.hide();
            console.log(err, '=== get customer error =======');
            this.bsService.notifyError(err, this.iSprintData.sku);
          }
        );
      }
    }, err=>{
      this.spinner.hide();
      this.bsService.notifyError(err, {mobile});
    });
    console.log(this.suspiciousCount, 'suspiciousCount');
  }

  onClickOk() {
    this.exceedLimitModal.hide();
    this.router.navigate(['/profile']);
  }

  onClickCancelModalTwo() {
    this.congratsModalStepTwo.hide();
  }
}
